import { Box, Typography, Grid, Container } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

import Wave from '../../assets/img/telepathology_dx/wave.png';
import { New } from 'components/common';
import { useEffect, useState } from 'react';
import axios from 'axios';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: '125px',
      minHeight: '100vh',
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: '75px'
      },
      position: 'relative',
      overflowX: 'hidden'
    },
    wave: {
      position: 'absolute',
      top: '10rem',
      width: '100%'
    },
    container: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
      }
    },
    gridPadding: {
      paddingRight: '3rem',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '0'
      }
    },
    gridPaddingLeft: {
      paddingLeft: '3rem',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0',
        marginTop: '90px !important'
      }
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '500 !important',
      lineHeight: '129% !important',
      color: '#20C2E5 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    desc: {
      fontSize: '20px !important',
      fontWeight: '400 !important',
      marginTop: '3rem !important',
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px !important',
        fontSize: '16px !important'
      }
    },
    newsBox: {
      maxWidth: '480px',
      width: '100%',
      minHeight: '630px',
      height: '100%',
      flexShrink: 0,
      paddingTop: '30px',
      marginRight: '20px',
      marginLeft: '20px',
      [theme.breakpoints.down('sm')]: {
        padding: '30px 0px',
        marginLeft: '0px'
      }
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        order: 1
      }
    },
    newsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    }
  })
);

const News = () => {
  const classes = useStyles();
  const [blogPosts, setBlogPosts] = useState<any[]>([]);

  const newReleases = [
    {
      date: '2024-07-31T11:19:14',
      description: `<p> ZayaAI, a pioneering force in
            AI-powered pathology diagnostics, is thrilled to announce its
            strategic collaboration with DAO Maker, a premier launchpad for
            blockchain-based projects. This partnership aims to revolutionize
            healthcare diagnostics by leveraging the combined power of
            artificial intelligence and blockchain technology.</p>`,
      image: '/news/dao-maker.jpg',
      link: '/articles/dao-maker',
      title:
        'ZayaAI Partners with DAO Maker to Launch $ZAYA Token, Advancing AI-Driven Pathology Diagnostics with Blockchain',
      id: '0'
    },
    {
      date: '2024-07-31T11:19:14',
      description: `<p> ZayaAI, a trailblazer in
            AI-powered pathology diagnostics, proudly announces its strategic
            partnership with Kommunitas, a leading decentralized and tier-less
            launchpad for crypto projects. This collaboration aims to harness
            the synergistic power of artificial intelligence and blockchain
            technology to significantly enhance healthcare diagnostics and
            outcomes.</p>`,
      image: '/news/kommunitas.jpg',
      link: '/articles/kommunitas',
      title:
        ' ZayaAI Collaborates with Kommunitas to Revolutionize Healthcare Diagnostics through AI and Blockchain',
      id: '1'
    },
    {
      date: '2024-07-17T11:19:14',
      description: `<p>  This partnership features an upcoming Initial DEX Offering (IDO) for
            $ZAYA, facilitated by Spores Network. Known for its multi-chain
            integrations and successful project launches, Spores Network will be
            critical in advancing the reach and impact of ZayaAI’s innovative
            diagnostic solutions.</p>`,
      image: '/news/spores.jpg',
      link: '/articles/spores',
      title:
        'ZayaAI Partners with Spores Network to Launch $ZAYA Token via IDO, Pioneering AI-Powered Pathology Diagnostics with Blockchain Integration',
      id: '2'
    },
    {
      date: '2024-07-10T11:19:14',
      description: `<p> Recently ranked as one of the “Top Crypto Launchpads in the World'',
            BullPerks has established itself as a premier launchpad, empowering
            visionary projects to elevate blockchain technology. With a proven
            track record of 65 successful projects and $15 million raised,
            BullPerks stands as an ideal partner for ZayaAI.</p>`,
      image: '/news/bullperks.jpg',
      link: '/articles/bullperks',
      title:
        'Digital Pathology Innovator, ZayaAI to launch on BullPerks Launchpad!',
      id: '3'
    },
    {
      date: '2024-07-10T11:19:14',
      description: `<p>ZayaAI, a pioneering
            AI-focused technology company, proudly announces its strategic
            partnership with Enjinstarter, a leading Web3 launchpad and
            advisory. The collaboration aims to harness the power of artificial
            intelligence and blockchain technology to revolutionize healthcare
            diagnostics and outcomes.</p>`,
      image: '/news/enjinstarter.jpg',
      link: '/articles/enjinstarter',
      title:
        ' ZayaAI Partners with Enjinstarter to Transform Healthcare with AI and Blockchain Integration',
      id: '4'
    },
    {
      date: '2024-05-20T11:19:14',
      description:
        '<p>Renowned for their commitment to advancing the decentralization movement, Oddiyana Ventures stands as a beacon in the investment space. They have successfully collaborated with over 150 esteemed venture partners worldwide, invested in over 100 pioneering projects, and nurtured the growth of over 15 ventures. With Oddiyana Ventures on board as our partner, ZayaAI is set for an exciting future!</p>',
      image: '/news/1.jpeg',
      link: '/articles/oddiyana',
      title: 'We are pleased to announce our partnership with Oddiyana!',
      id: '5'
    },
    {
      date: '2024-05-15T11:19:14',
      description:
        '<p>Big things are happening! We have teamed up with Skynet Trading, a licensed boutique firm deeply focused on developing trading strategies for the #cryptocurrency market! Skynet Trading has been a designated Market Maker and Advisory firm for #digitalassets and exchanges for *over 4 years*. Their team provides institutional-grade liquidity solutions and quantitative trading strategies through their in-house built, non-custodial trading software.</p>',
      image: '/news/2.jpeg',
      link: '',
      title: 'We have teamed up with Skynet Trading!',
      id: '6'
    },
    {
      date: '2024-05-15T11:19:14',
      description:
        "<p>ZayaAI, a comprehensive ecosystem dedicated to combating cancer using advanced artificial intelligence (AI), is pleased to announce the appointment of Luna PR, an acclaimed agency recognized for its expertise in Web3 and emerging technologies. This collaboration marks a significant milestone for ZayaAI, leveraging Luna PR's proficiency to amplify visibility and communicate the company's mission and competencies across both Web2 and Web3 platforms.</p>",
      image: '/news/3.png',
      link: '/articles/luna',
      title: 'ZayaAI Appoints Luna PR to Lead Web2-Web3 Integration',
      id: '7'
    }
  ];

  const getBlogPosts = async () => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/posts/get-posts`,
      method: 'get'
    });
    const posts = response.data.posts;
    setBlogPosts(posts);
  };

  useEffect(() => {
    getBlogPosts();
  }, []);

  return (
    <Box className={classes.root}>
      <img src={Wave} className={classes.wave} />
      <Container maxWidth='xl' className={classes.container}>
        <Box sx={{ mt: { xs: '0', md: '175px' }, mb: { xs: '0', md: '50px' } }}>
          <Typography className={classes.title}>News</Typography>
        </Box>
        <div className={classes.newsContainer}>
          <Grid container>
            {newReleases.map((item, index) => (
              <Grid key={index} item lg={4} md={6}>
                <Box className={classes.newsBox}>
                  <New
                    date={item.date}
                    header={item.title}
                    content={item.description}
                    url={item.link}
                    image={item.image}
                  />
                </Box>
              </Grid>
            ))}
            {blogPosts?.map((item, index) => (
              <Grid key={index} item lg={4} md={6}>
                <Box key={index} className={classes.newsBox}>
                  <New
                    date={item.date}
                    header={item.title}
                    content={item.description}
                    url={item.link}
                    image={item.image}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </Box>
  );
};

export { News };
