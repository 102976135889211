import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import {
  useGetAccountInfo,
  useGetIsLoggedIn,
  useGetNetworkConfig
} from '@multiversx/sdk-dapp/hooks';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StakingModal from 'components/StakingModal/StakingModal';
import StakingTable from 'components/StakingTable/StakingTable';
import { StakingMethods } from 'helpers/smartcontract/stakingMethods';
import { bigNumberToFloat } from 'helpers/smartcontract/utils';
import wave from '../../assets/img/blockchain/wave.svg';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      background:
        'linear-gradient(51deg, #E7F0FF 0%, rgba(232, 241, 255, 0.47) 100%)',
      paddingTop: '10rem',
      color: '#16374D !important',
      position: 'relative'
    },
    main: {
      padding: '180px',
      position: 'relative',
      [theme.breakpoints.down('xl')]: {
        padding: '6rem'
      },
      [theme.breakpoints.down('md')]: {
        padding: '4rem'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '1rem'
      }
    },
    gridContainer: {},
    title: {
      fontSize: '71px !important',
      fontWeight: '500 !important',
      lineHeight: '129% !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    blueT: {
      color: '#20C2E5 !important'
    },
    content: {
      marginTop: '25px !important',
      fontSize: '20px !important',
      fontWeight: '300 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important'
      }
    },
    wave: {
      position: 'absolute',
      width: '100%',
      top: '36rem',
      maxWidth: '1750px',
      [theme.breakpoints.down('sm')]: {
        display: 'none !important'
      }
    },
    stakingCard: {
      background:
        'linear-gradient(180deg, #031420 0%, #0F3249 101.33%, #3E5F75 101.33%)',
      borderRadius: '20px',
      padding: '39px 34px',
      maxWidth: '670px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '24px'
      }
    },
    stakingTitle: {
      color: '#FFF',
      fontSize: '28px',
      fontWeight: '700',
      marginBottom: '20px'
    },
    description: {
      color: '#FFF',
      fontSize: '12px',
      marginBottom: '20px',
      fontWeight: 400
    },
    stakingCardWhite: {
      background: '#fff',
      borderRadius: '20px',
      padding: '20px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '16px'
      }
    },
    cardTitle: {
      color: '#1B2B38',
      fontSize: '21.184px',
      fontWeight: 500,
      lineHeight: '24px',
      marginLeft: '10px'
    },
    gridItem: {
      display: 'flex',
      alignItems: 'center'
    },
    balanceTitle: {
      color: '#8B8B8B',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      marginBottom: '4px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px'
      }
    },
    balanceValue: {
      color: '#1B2B38',
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '27px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px'
      }
    },
    fullButton: {
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      display: 'flex',
      width: '105px',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#1B2B38 !important',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      marginRight: '5px !important',
      border: 'none !important',
      [theme.breakpoints.down('sm')]: {
        width: '85px'
      }
    },
    fullButtonBig: {
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      display: 'flex',
      width: '105px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#1B2B38 !important',
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 600,
      padding: '8px',
      marginRight: '5px !important',
      border: 'none !important',
      marginTop: '20px'
    },
    outlineButton: {
      borderRadius: '85px !important',
      background: 'white',
      display: 'flex',
      width: '105px',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#16374D !important',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      border: '0.56px solid #20C2E5 !important',
      [theme.breakpoints.down('sm')]: {
        width: '85px'
      }
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('lg')]: {
        justifyContent: 'center',
        marginTop: '40px !important'
      }
    },
    heightDisplay: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
);

const Staking = () => {
  const classes = useStyles();
  const isLoggedIn = useGetIsLoggedIn();
  const { address } = useGetAccountInfo();
  const {
    network: { apiAddress }
  } = useGetNetworkConfig();
  const navigate = useNavigate();
  const networkProvider = new ProxyNetworkProvider(apiAddress);
  const [walletTokens, setWalletTokens] = useState(0);
  const BigZero = new BigNumber(0);
  const [openStakeModal, setOpenStakeModal] = useState(false);
  const sharedConfig = {
    contractAddress: process.env.REACT_APP_STAKING_CONTRACT_ADDRESS as string,
    networkProviderUrl: process.env.REACT_APP_BASE_URL_MULTIVERSX as string,
    chainId: '1',
    gasLimit: 50000000
  };
  const [stakes, setStakes] = useState<any>([]);
  const [refreshStakes, setRefreshStakes] = useState(false);
  const [stakeInfo, setStakeInfo] = useState({
    stakingPeriod: 0,
    rewardPercentage: 0,
    totalStaked: 0,
    totalReward: 0
  });

  const [reward, setReward] = useState(1.38);

  const getTokens = async () => {
    const tokens = await axios.get(
      `${process.env.REACT_APP_BASE_URL_MULTIVERSX}/accounts/${address}/tokens/${process.env.REACT_APP_TOKEN_IDENTIFIER}`
    );
    setWalletTokens(tokens.data.balance / Math.pow(10, 18));
  };

  const getStakes = async () => {
    const response = await StakingMethods.getUserData({
      config: { ...sharedConfig },
      user: address
    });
    setStakes(response.stakes);
    setRefreshStakes(false);

    const newReward = response.stakes
      .filter((el) => !el.stake.claimed && !el.stake.auto_restake)
      .reduce((accumulator, currentValue) => {
        return (
          accumulator +
          bigNumberToFloat(currentValue.will_claim) -
          bigNumberToFloat(currentValue.stake.initial_amount)
        );
      }, 0);

    setStakeInfo((prev) => ({
      ...prev,
      totalStaked: bigNumberToFloat(response.total_staked),
      totalReward: newReward
    }));
  };

  useEffect(() => {
    // navigate('/');

    getTokens();
    getStakes();
  }, []);

  useEffect(() => {
    if (refreshStakes) {
      getTokens();
      getStakes();
    }
  }, [refreshStakes]);

  const handleStake = () => {
    if (!isLoggedIn) {
      toast.warn('You have to be logged in in order to stake');
      return;
    }

    setOpenStakeModal(true);
  };

  return (
    <Box className={classes.root}>
      <img src={wave} className={classes.wave} />
      <Box className={classes.main}>
        <Grid container className={classes.gridContainer}>
          <Grid item xl={5} lg={5} md={12}>
            <Typography className={classes.title}>
              Staking on <span className={classes.blueT}>Zaya AI</span>
            </Typography>
            <Typography className={classes.content}>
              ZayaAI is a comprehensive ecosystem designed to fight cancer and
              promote healthy living through the use of cutting-edge AI
              technologies and advanced medical screening techniques.
            </Typography>

            <button
              onClick={() => handleStake()}
              className={classes.fullButtonBig}
            >
              Stake
            </button>
          </Grid>

          <Grid item xl={1} lg={1} md={0}></Grid>

          <Grid item xl={6} lg={6} md={12} className={classes.cardContainer}>
            <Box className={classes.stakingCard}>
              <Box className={classes.stakingTitle}>Stake your $Zaya</Box>
              <Box className={classes.description}>
                ZayaAI is a ground-breaking ecosystem that aims to combat
                cancers by leveraging the latest AI technologies and medical
                screening procedures to provide early detection, prevention, and
                treatment.
              </Box>
              <Grid container className={classes.stakingCardWhite}>
                <Grid
                  item
                  md={12}
                  className={classes.gridItem}
                  style={{ marginBottom: '20px' }}
                >
                  <img
                    src='/zaya-logo.svg'
                    style={{ width: '24px', height: '24px' }}
                  />
                  <div className={classes.cardTitle}>
                    $ZAYA Balance: {walletTokens.toFixed(2)}
                  </div>
                </Grid>

                <Grid
                  item
                  md={12}
                  className={classes.gridItem}
                  style={{ marginBottom: '20px', width: '100%' }}
                >
                  <Grid container style={{ width: '100%' }}>
                    <Grid
                      item
                      xs={3}
                      className={classes.heightDisplay}
                      style={{ flexDirection: 'column' }}
                    >
                      <div className={classes.balanceTitle}>Total staked</div>
                      <div className={classes.balanceValue}>
                        {stakeInfo.totalStaked}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.heightDisplay}
                      style={{ flexDirection: 'column' }}
                    >
                      <div className={classes.balanceTitle}>Total reward</div>
                      <div className={classes.balanceValue}>
                        {stakeInfo.totalReward.toFixed(4)}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.heightDisplay}
                      style={{ flexDirection: 'column' }}
                    >
                      <div className={classes.balanceTitle}>APR</div>
                      <div className={classes.balanceValue}>
                        {reward}%/ 30 days
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.heightDisplay}
                      style={{ flexDirection: 'column' }}
                    >
                      <div className={classes.balanceTitle}>
                        Min staking period
                      </div>
                      <div className={classes.balanceValue}>90 days</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {!!stakes.length && (
          <StakingTable
            stakes={stakes}
            setRefreshStakes={setRefreshStakes}
            config={{ ...sharedConfig, callerAddress: address }}
            address={address}
          />
        )}
      </Box>

      {openStakeModal && (
        <StakingModal
          balance={walletTokens}
          reward={reward}
          setReward={setReward}
          setOpen={setOpenStakeModal}
          setRefreshStakes={setRefreshStakes}
          config={{ ...sharedConfig, callerAddress: address }}
        />
      )}
    </Box>
  );
};

export { Staking };
