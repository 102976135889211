import { Box, Typography, Container } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

import Wave from '../../../assets/img/telepathology_dx/wave.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: '125px',
      minHeight: '100vh',
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: '75px'
      },
      position: 'relative',
      overflowX: 'hidden'
    },
    wave: {
      position: 'absolute',
      top: '10rem',
      width: '100%'
    },
    container: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
      }
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '600 !important',
      lineHeight: '129% !important',
      color: '#20C2E5 !important',
      textAlign: 'center',
      [theme.breakpoints.down('lg')]: {
        fontSize: '60px !important'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    content: {
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    image: {
      width: '100%',
      maxWidth: '600px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '400px'
      }
    }
  })
);

const Kommunitas = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={Wave} className={classes.wave} />
      <Container maxWidth='xl' className={classes.container}>
        <Box
          sx={{
            mt: { xs: '0', lg: '175px' },
            mb: '50px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '60px'
          }}
        >
          <Typography className={classes.title}>
            ZayaAI Collaborates with Kommunitas to Revolutionize Healthcare
            Diagnostics through AI and Blockchain
          </Typography>
          <img src='/news/kommunitas.jpg' className={classes.image} />
        </Box>
        <div>
          <p className={classes.content}>
            <b>[Bucharest, July 31, 2024]</b> - ZayaAI, a trailblazer in
            AI-powered pathology diagnostics, proudly announces its strategic
            partnership with Kommunitas, a leading decentralized and tier-less
            launchpad for crypto projects. This collaboration aims to harness
            the synergistic power of artificial intelligence and blockchain
            technology to significantly enhance healthcare diagnostics and
            outcomes.
          </p>{' '}
          <br />
          <p className={classes.content}>
            ZayaAI’s advanced AI software empowers pathologists and researchers
            in clinical labs to reduce diagnosis times, decrease costs, minimize
            human error, and ultimately enhance patient care. By integrating
            blockchain technology, ZayaAI ensures data security, transparency,
            and improved collaboration across the healthcare ecosystem.
          </p>{' '}
          <br />
          <p>
            This partnership features an upcoming Initial DEX Offering (IDO) for
            $ZAYA, facilitated by Kommunitas. Known for its robust platform and
            successful project launches, Kommunitas will play a crucial role in
            expanding the reach and impact of ZayaAI’s innovative diagnostic
            solutions. The IDO, set to launch in September 2024, presents a
            unique opportunity for early investors to support ZayaAI’s vision
            and partake in a transformative journey in medical diagnostics.
          </p>
          <br />
          <p className={classes.content}>
            <b>Cristian Mogodici, CEO of ZayaAI</b>, expressed his enthusiasm:
            “Partnering with Kommunitas is a significant step forward for
            ZayaAI. Their expertise in decentralized crowdfunding and their
            strong investor community align perfectly with our mission to drive
            healthcare innovation through advanced digital technologies.”
          </p>{' '}
          <br />
          <p className={classes.content}>
            The partnership announcement has already generated substantial
            interest, underscoring the community’s support and anticipation for
            the upcoming IDO.
          </p>{' '}
          <br />
          <a href='https://www.zaya.ai/' style={{ fontWeight: 500 }}>
            About ZayaAI
          </a>
          <p className={classes.content}>
            ZayaAI equips pathologists and researchers in clinical labs with
            powerful deep-learning artificial intelligence software for
            translating images into discoveries, decisions, and diagnoses. The
            cloud-based ZayaAI products and services aim to improve the
            efficiency and precision of pathology diagnosis and image analysis
            beyond current capabilities across various fields, from oncology to
            dermatopathology.
          </p>
          <br />
          <a href='https://kommunitas.net/' style={{ fontWeight: 500 }}>
            About Kommunitas
          </a>
          <p className={classes.content}>
            Kommunitas is a decentralized and tier-less launchpad platform that
            supports crypto projects through Initial DEX Offerings (IDOs). With
            a focus on community building and inclusivity, Kommunitas empowers
            visionary projects by providing them with the necessary resources
            and exposure to achieve their goals in the blockchain space.
          </p>
        </div>
      </Container>
    </Box>
  );
};

export { Kommunitas };
