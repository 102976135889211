import { Box, Typography, Container } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

import Wave from '../../../assets/img/telepathology_dx/wave.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: '125px',
      minHeight: '100vh',
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: '75px'
      },
      position: 'relative',
      overflowX: 'hidden'
    },
    wave: {
      position: 'absolute',
      top: '10rem',
      width: '100%'
    },
    container: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
      }
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '600 !important',
      lineHeight: '129% !important',
      color: '#20C2E5 !important',
      textAlign: 'center',
      [theme.breakpoints.down('lg')]: {
        fontSize: '60px !important'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    content: {
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    image: {
      width: '100%',
      maxWidth: '600px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '400px'
      }
    }
  })
);

const DaoMaker = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={Wave} className={classes.wave} />
      <Container maxWidth='xl' className={classes.container}>
        <Box
          sx={{
            mt: { xs: '0', lg: '175px' },
            mb: '50px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '60px'
          }}
        >
          <Typography className={classes.title}>
            ZayaAI Partners with DAO Maker to Launch $ZAYA Token, Advancing
            AI-Driven Pathology Diagnostics with Blockchain
          </Typography>
          <img src='/news/dao-maker.jpg' className={classes.image} />
        </Box>
        <div>
          <p className={classes.content}>
            <b>[Bucharest, July 31, 2024]</b> - ZayaAI, a pioneering force in
            AI-powered pathology diagnostics, is thrilled to announce its
            strategic collaboration with DAO Maker, a premier launchpad for
            blockchain-based projects. This partnership aims to revolutionize
            healthcare diagnostics by leveraging the combined power of
            artificial intelligence and blockchain technology.
          </p>{' '}
          <br />
          <p className={classes.content}>
            ZayaAI’s cutting-edge AI software provides pathologists and
            researchers in clinical laboratories with tools that significantly
            reduce diagnosis times, lower costs, decrease human error, and
            improve patient outcomes. By incorporating blockchain technology,
            ZayaAI enhances data security, transparency, and collaborative
            efforts within the healthcare sector.
          </p>{' '}
          <br />
          <p>
            This partnership features an upcoming Initial DEX Offering (IDO) for
            $ZAYA, facilitated by DAO Maker. Renowned for its robust platform
            and successful project launches, DAO Maker will play a critical role
            in extending the reach and impact of ZayaAI’s innovative diagnostic
            solutions. The IDO, set to launch in September 2024, offers a unique
            opportunity for early investors to support ZayaAI’s vision and join
            a transformative journey in medical diagnostics.
          </p>
          <br />
          <p className={classes.content}>
            <b>Cristian Mogodici, CEO of ZayaAI</b>, shared his excitement:
            “Partnering with DAO Maker is a monumental step forward for ZayaAI.
            Their expertise in blockchain-based crowdfunding and their dedicated
            investor community align perfectly with our mission to drive
            healthcare innovation through advanced digital technologies.”
          </p>{' '}
          <br />
          <p className={classes.content}>
            The announcement of this partnership has already generated
            significant interest, highlighting the community’s strong support
            and anticipation for the forthcoming IDO.
          </p>{' '}
          <br />
          <a href='https://www.zaya.ai/' style={{ fontWeight: 500 }}>
            About ZayaAI
          </a>
          <p className={classes.content}>
            ZayaAI equips pathologists and researchers in clinical labs with
            powerful deep-learning artificial intelligence software for
            translating images into discoveries, decisions, and diagnoses. The
            cloud-based ZayaAI products and services aim to improve the
            efficiency and precision of pathology diagnosis and image analysis
            beyond current capabilities across various fields, from oncology to
            dermatopathology.
          </p>
          <br />
          <a
            href='https://app.daomaker.com/launchpad/'
            style={{ fontWeight: 500 }}
          >
            DAO Maker
          </a>
          <p className={classes.content}>
            DAO Maker is a leading platform that offers a comprehensive
            launchpad for blockchain-based projects. It supports startups
            through various fundraising mechanisms, including Initial DEX
            Offerings (IDOs), fostering community engagement and providing
            essential resources for project success in the blockchain ecosystem.
          </p>
        </div>
      </Container>
    </Box>
  );
};

export { DaoMaker };
