import { Box, Typography, Container } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

import Wave from '../../../assets/img/telepathology_dx/wave.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: '125px',
      minHeight: '100vh',
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: '75px'
      },
      position: 'relative',
      overflowX: 'hidden'
    },
    wave: {
      position: 'absolute',
      top: '10rem',
      width: '100%'
    },
    container: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
      }
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '600 !important',
      lineHeight: '129% !important',
      color: '#20C2E5 !important',
      textAlign: 'center',
      [theme.breakpoints.down('lg')]: {
        fontSize: '60px !important'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    content: {
      fontSize: '16px',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    image: {
      width: '100%',
      maxWidth: '600px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '400px'
      }
    }
  })
);

const BullPerks = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={Wave} className={classes.wave} />
      <Container maxWidth='xl' className={classes.container}>
        <Box
          sx={{
            mt: { xs: '0', lg: '175px' },
            mb: '50px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '60px'
          }}
        >
          <Typography className={classes.title}>
            Digital Pathology Innovator, ZayaAI to launch on BullPerks Launchpad
            - Elevating HealthTech in the Blockchain Space
          </Typography>
          <img src='/news/bullperks.jpg' className={classes.image} />
        </Box>
        <div>
          <p className={classes.content}>
            <b>Dubai, 1 July, 2024</b> – ZayaAI, an innovative leader in AI and
            blockchain technology has announced its $ZAYA utility token, the
            central element to the ZayaAI ecosystem, is set to launch on the
            BullPerks launchpad, a renowned platform in the crypto crowdfunding
            space.
          </p>{' '}
          <br />
          <p className={classes.content}>
            Recently ranked as one of the “Top Crypto Launchpads in the World'',
            BullPerks has established itself as a premier launchpad, empowering
            visionary projects to elevate blockchain technology. With a proven
            track record of 65 successful projects and $15 million raised,
            BullPerks stands as an ideal partner for ZayaAI.
          </p>{' '}
          <br />
          <p className={classes.content}>
            Ahead of the token launch Q3 2024, the collaboration marks a
            significant milestone for ZayaAI. Connecting directly with
            BullPerks' extensive community known for its active support of
            projects that push the boundaries of blockchain and artificial
            intelligence.
          </p>{' '}
          <br />
          <p className={classes.content}>
            The $ZAYA token serves as the cornerstone of the ZayaAI ecosystem,
            offering various utilities that enhance the user experience and
            incentivize participation:
          </p>
          <ul style={{ listStyleType: 'circle' }}>
            <li className={classes.content}>
              • Utility and Governance: $ZAYA tokens will be used to access
              premium features within the ZayaAI platform, participate in
              governance decisions, and vote on key developments and proposals.
            </li>
            <li className={classes.content}>
              • Incentives and Rewards: Users can earn $ZAYA tokens through
              various activities such as contributing data, participating in
              research, and using the platform’s diagnostic tools.
            </li>
            <li className={classes.content}>
              • Staking and Yield Farming: Token holders will have the
              opportunity to stake their $ZAYA tokens to earn rewards, promoting
              long-term engagement and stability within the ecosystem.
            </li>
            <li className={classes.content}>
              • Access to AI Services: $ZAYA tokens will be required to access
              advanced AI-driven diagnostic services, providing early detection
              and personalized treatment options for cancer.
            </li>
            <li className={classes.content}>
              • Partnership and Collaborations: The token will facilitate
              partnerships with medical institutions, research organizations,
              and blockchain projects, expanding the ecosystem and enhancing the
              platform's capabilities.
            </li>
          </ul>
          <p className={classes.content}>
            BullPerks' expertise in crypto crowdfunding, combined with ZayaAI's
            groundbreaking AI and blockchain integration, creates a synergy that
            promises to drive significant advancements and opportunities in the
            industry.
          </p>
          <br />
          <p className={classes.content}>
            <b>Cristian Mogodici, CEO of ZayaAI</b>: "We are thrilled to partner
            with BullPerks and bring $ZAYA token to their dynamic platform,"
            said Cristian Mogodici, CEO of ZayaAI. "This launch is a testament
            to our commitment to innovation and our belief in the transformative
            power of blockchain and AI. We look forward to engaging with the
            BullPerks community and achieving new heights together."
          </p>{' '}
          <br />
          <a href='https://www.zaya.ai/' style={{ fontWeight: 500 }}>
            About ZayaAI
          </a>
          <p className={classes.content}>
            Zaya AI is at the forefront of integrating artificial intelligence
            with blockchain technology. Their mission is to empower pathologists
            by creating intelligent solutions that enhance the efficiency and
            accuracy of pathology diagnoses.
          </p>{' '}
          <br />
        </div>
      </Container>
    </Box>
  );
};

export { BullPerks };
