import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import {
  AbiRegistry,
  Account,
  Address,
  AddressValue,
  ArgSerializer,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  ResultsParser,
  SmartContract,
  StringValue,
  TokenIdentifierValue,
  TokenPayment,
  Transaction,
  TransactionPayload,
  TypedValue,
  U32Value,
  U64Value
} from '@multiversx/sdk-core/out';
import {
  useGetAccountInfo,
  useGetIsLoggedIn,
  useGetNetworkConfig,
  useGetPendingTransactions
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { toast } from 'react-toastify';
import Abi from 'assets/abi/zaya_staking.abi.json';
import { contracts } from 'config';
import {
  claim,
  getInvestorInfo,
  getMaintenanceStatus
} from 'helpers/smartcontract';
import { convertWeiToEsdt, getDeltaTime, shortenEsdt } from 'utils';
import {
  UserPrivateVestingInfoContext,
  UserVestingInfoContext
} from 'utils/types';
import wave from '../../assets/img/blockchain/wave.svg';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      background:
        'linear-gradient(51deg, #E7F0FF 0%, rgba(232, 241, 255, 0.47) 100%)',
      paddingTop: '10rem',
      color: '#16374D !important',
      position: 'relative'
    },
    main: {
      padding: '185px',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem'
      }
    },
    gridContainer: {},
    title: {
      fontSize: '71px !important',
      fontWeight: '500 !important',
      lineHeight: '129% !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    blueT: {
      color: '#20C2E5 !important'
    },
    content: {
      marginTop: '25px !important',
      fontSize: '20px !important',
      fontWeight: '300 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important'
      }
    },
    vestingType: {
      width: '48% !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      border: '1px solid #20C2E5 !important',
      background: 'transparent !important',
      fontSize: '16px !important',
      color: 'black !important',
      marginLeft: '5px !important',
      marginTop: '17px !Important',
      padding: '8px 15px !important',
      [theme.breakpoints.down('sm')]: {
        padding: '12px 18px !important',
        fontSize: '10px !important'
        // fontSize: '14px !important',
      }
    },
    vestingClicked: {
      background: '#20C2E5 !important',
      border: 'none'
    },
    gridStakeContainer: {
      padding: '0 3rem',
      [theme.breakpoints.down('lg')]: {
        marginTop: '2rem !important'
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
        marginTop: '1rem !important'
      }
    },
    vestingBox: {
      borderRadius: '20px',
      background:
        'linear-gradient(180deg, #031420 0%, #0F3249 100%, #3E5F75 100%)',
      padding: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
        // marginLeft: '-25px !important',
        // marginRight: '-25px !important',
        // borderRadius: '0px',
      }
    },
    stakeTitle: {
      color: '#FFF !important',
      fontSize: '28px !important',
      fontWeight: '700 !important',
      lineHeight: 'normal !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px !important'
      }
    },
    buyTitle: {
      color: '#FFF !important',
      fontSize: '28px !important',
      fontWeight: '700 !important',
      lineHeight: 'normal !important',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px !important'
      }
    },
    buyField: {
      marginTop: '38px !important'
    },
    maxBtn: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer'
    },
    buyTextField: {
      borderRadius: '10px',
      background: '#f7f7f7',
      '& fieldset': {
        border: 'none !important'
      }
    },
    buyButton: {
      width: '100% !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      fontSize: '16px !important',
      color: 'black !important',
      marginTop: '30px !Important',
      padding: '8px 24px !important',
      [theme.breakpoints.down('sm')]: {
        padding: '12px 18px !important',
        fontSize: '10px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },
    vestingInfoText: {
      fontSize: '16px !important',
      fontWeight: '400 !important',
      textAlign: 'right',
      color: 'white',
      marginRight: '10px !important',
      marginTop: '8px !important'
    },
    stakeContent: {
      fontSize: '12px !important',
      fontWeight: '400 !important',
      color: '#FFF !important',
      marginTop: '2rem !important',
      textAlign: 'center'
    },
    subStakeBox: {
      borderRadius: '20px !important',
      background: '#FFF !important',
      marginTop: '2rem !important',
      padding: '18px !important'
    },
    subStakeTitle: {
      color: '#1B2B38',
      fontSize: '21.184px !important',
      fontWeight: '500 !important',
      lineHeight: '23.832px !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px !important'
      }
    },
    infoContainer: {
      marginTop: '2rem !important'
    },
    infoTitle: {
      fontSize: '18px !important',
      fontWeight: '500 !important',
      lineHeight: '26px !important',
      color: '#8B8B8B !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important'
      }
    },
    infoContent: {
      fontSize: '18px !important',
      fontWeight: '500 !important',
      lineHeight: '26px !important',
      color: '#1B2B38 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '15px !important'
      }
    },
    buttonGroup: {
      textAlign: 'right',
      marginTop: '2rem !important'
    },
    blueButton: {
      width: '100px !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      fontSize: '13px !important',
      color: 'black !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('sm')]: {
        width: '80px !important',
        fontSize: '10px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },
    blueButtonw100: {
      width: '100px !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      background: '#20C2E5 !important',
      fontSize: '13px !important',
      color: 'black !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        fontSize: '16px !important',
        padding: '10px !important',
        marginTop: '0.5rem !important',
        marginLeft: '0px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },
    whiteButton: {
      width: '100px !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      border: '0.56px solid #20C2E5 !important',
      background: '#FFF !important',
      fontSize: '13px !important',
      color: '#16374D !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('sm')]: {
        width: '80px !important',
        fontSize: '10px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },
    whiteButtonw100: {
      width: '100px !important',
      textAlign: 'center',
      borderRadius: '85px !important',
      border: '0.56px solid #20C2E5 !important',
      background: '#FFF !important',
      fontSize: '13px !important',
      color: '#16374D !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        fontSize: '16px !important',
        marginTop: '0.5rem !important',
        marginLeft: '0px !important',
        padding: '10px !important'
        // fontSize: '14px !important',
      },
      '&:hover': {
        // background: 'transparent linear-gradient(90deg, #FF8913 0%, #FF5F07 50%, #D02D02 100%) 0% 0% no-repeat padding-box !important',
        // boxShadow: '0px 0px 10px #FF891326'
      }
    },

    tableContainer: {
      borderRadius: '12px !important',
      marginTop: '150px !important',
      [theme.breakpoints.down('sm')]: {
        marginTop: '5rem !important'
        // fontSize: '14px !important',
      }
    },
    table: {
      minWidth: 700,
      borderRadius: '10px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'unset !important'
      }
    },
    head: {
      background: '#E9E9E9'
    },
    rowHighlight: {
      background: 'rgba(32, 194, 229, 0.44)'
    },
    rowNormal: {
      background: '#FFF'
    },
    cell: {
      borderBottom: '1px light gray !important'
    },
    cellBold: {
      fontWeight: 'bold !important'
    },
    wave: {
      position: 'absolute',
      width: '100%',
      top: '36rem',
      maxWidth: '1750px',
      [theme.breakpoints.down('sm')]: {
        display: 'none !important'
      }
    },

    textField: {
      borderRadius: '10px',
      background: '#f7f7f7',
      margin: '1rem !important',
      '& fieldset': {
        border: 'none !important'
      }
    },
    agreeFont: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important'
      }
    },
    mt15: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px !important'
      }
    }
  })
);

const Vesting = () => {
  const classes = useStyles();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const isLoggedIn = useGetIsLoggedIn();
  const { address } = useGetAccountInfo();
  const {
    network: { apiAddress }
  } = useGetNetworkConfig();
  const networkProvider = new ProxyNetworkProvider(apiAddress);
  const BigZero = new BigNumber(0);
  const [userData, setUserData] = useState({
    claimable: 0,
    vested: 0,
    locked: 0,
    claimed: 0
  });
  // vesting
  const contractAddress = new Address(contracts.ZayaVesting.address);
  const contractAbi = AbiRegistry.create(contracts.ZayaVesting.abi);
  const contract = new SmartContract({
    address: contractAddress,
    abi: contractAbi
  });

  // private vesting
  const privateVestingContractAddress = new Address(
    contracts.ZayaPrivateVesting.address
  );
  const privateVestingContractAbi = AbiRegistry.create(
    contracts.ZayaPrivateVesting.abi
  );
  const privateVestingContract = new SmartContract({
    address: privateVestingContractAddress,
    abi: privateVestingContractAbi
  });

  const [isSeedSale, setIsSeedSale] = useState<boolean>(true);
  const [userVestingInfo, setUserVestingInfo] = useState<
    UserVestingInfoContext | undefined
  >();
  const [userPrivateVestingInfo, setUserPrivateVestingInfo] = useState<
    UserPrivateVestingInfoContext | undefined
  >();
  const [saleCliffTimestamp, setSaleCliffTimestamp] = useState(0);
  const [privateSaleCliffTimestamp, setPrivateSaleCliffTimestamp] = useState(0);
  const [isMaintenanceActive, setIsMaintenanceActive] = useState(false);

  const checkMaintenance = async () => {
    const isMaintenanceStatusActive = await getMaintenanceStatus();
    setIsMaintenanceActive(isMaintenanceStatusActive);
    if (isMaintenanceActive) {
      toast.warn('The application is in maintenance', {
        autoClose: false,
        toastId: 'maintenance-status'
      });
    }
  };

  useEffect(() => {
    checkMaintenance();

    if (
      !contract ||
      !privateVestingContract ||
      !isLoggedIn ||
      hasPendingTransactions
    ) {
      return;
    }
  });

  const getInfo = async () => {
    const response = await getInvestorInfo(address);
    setUserData(response);
  };

  useEffect(() => {
    if (
      !contract ||
      !privateVestingContract ||
      !isLoggedIn ||
      hasPendingTransactions
    ) {
      return;
    }

    getInfo();

    (async () => {
      try {
        const query = contract.createQuery({
          func: new ContractFunction('getUserSaleInfo'),
          args: [new AddressValue(new Address(address))]
        });

        const queryResponse = await networkProvider.queryContract(query);

        const resultsParser = new ResultsParser();
        const endpointDefinition = contract.getEndpoint('getUserSaleInfo');

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        );

        const value = firstValue?.valueOf();

        const decodedValue: UserVestingInfoContext = {
          user_paid_usdc_amount: value.user_paid_usdc_amount.toFixed(),
          user_bought_amount: value.user_bought_amount.toFixed(),
          user_released_amount: value.user_released_amount.toFixed(),
          user_withdrawable_amount: value.user_withdrawable_amount.toFixed(),
          sale_start_at: value.sale_start_at.toNumber(),
          cliff_timestamp: value.cliff_timestamp.toNumber(),
          is_whitelisted: value.is_whitelisted
        };

        setUserVestingInfo(decodedValue);
        setSaleCliffTimestamp(decodedValue.cliff_timestamp);
      } catch (error) {
        console.log(error);
      }
    })();

    (async () => {
      try {
        const query = privateVestingContract.createQuery({
          func: new ContractFunction('getUserSaleInfo'),
          args: [new AddressValue(new Address(address))]
        });

        const queryResponse = await networkProvider.queryContract(query);

        const resultsParser = new ResultsParser();
        const endpointDefinition =
          privateVestingContract.getEndpoint('getUserSaleInfo');

        const { firstValue } = resultsParser.parseQueryResponse(
          queryResponse,
          endpointDefinition
        );

        const value = firstValue?.valueOf();

        const decodedValue: UserPrivateVestingInfoContext = {
          user_address: value.user_address.toString(),
          user_bought_amount: value.user_bought_amount.toFixed(),
          user_released_amount: value.user_released_amount.toFixed(),
          user_withdrawable_amount: value.user_withdrawable_amount.toFixed(),
          sale_start_at: value.sale_start_at.toNumber(),
          cliff_timestamp: value.cliff_timestamp.toNumber()
        };

        setUserPrivateVestingInfo(decodedValue);
        setPrivateSaleCliffTimestamp(decodedValue.cliff_timestamp);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [hasPendingTransactions, isLoggedIn]);

  const handleClaim = async () => {
    if (!isLoggedIn) {
      toast.warn('Please connect wallet.');
      return;
    }
    const data = await getInvestorInfo(address);
    await claim(address);
  };

  return (
    <Box className={classes.root}>
      <img src={wave} className={classes.wave} />
      <Box className={classes.main}>
        <Grid container className={classes.gridContainer}>
          <Grid item xl={6} lg={6} md={12}>
            <Typography className={classes.title}>
              Vesting on <span className={classes.blueT}>Zaya AI</span>
            </Typography>
            <Typography className={classes.content}>
              ZayaAI is a comprehensive ecosystem designed to fight cancer and
              promote healthy living through the use of cutting-edge AI
              technologies and advanced medical screening techniques.
            </Typography>
            {userPrivateVestingInfo &&
              BigZero.lt(userPrivateVestingInfo.user_bought_amount) && (
                <Typography className='d-flex'>
                  <Button
                    className={
                      isSeedSale
                        ? `${classes.vestingType} ${classes.vestingClicked}`
                        : classes.vestingType
                    }
                    onClick={() => setIsSeedSale(true)}
                  >
                    Seed Sale
                  </Button>
                  <Button
                    className={
                      isSeedSale
                        ? classes.vestingType
                        : `${classes.vestingType} ${classes.vestingClicked}`
                    }
                    onClick={() => setIsSeedSale(false)}
                  >
                    Private Sale
                  </Button>
                </Typography>
              )}
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={12}
            className={classes.gridStakeContainer}
          >
            <Box className={classes.vestingBox}>
              <Box className='d-flex justify-content-between'>
                <Typography className={classes.vestingInfoText}>
                  Your tokens
                </Typography>
                <Typography className={classes.vestingInfoText}>
                  {userData.vested} ZAYA
                </Typography>
              </Box>
              <Box className='d-flex justify-content-between'>
                <Typography className={classes.vestingInfoText}>
                  Released
                </Typography>
                <Typography className={classes.vestingInfoText}>
                  {userData.claimed} ZAYA
                </Typography>
              </Box>
              <Box className='d-flex justify-content-between'>
                <Typography className={classes.vestingInfoText}>
                  Locked
                </Typography>
                <Typography className={classes.vestingInfoText}>
                  {userData.locked} ZAYA
                </Typography>
              </Box>
              <Box className='d-flex justify-content-between'>
                <Typography className={classes.vestingInfoText}>
                  Unlocked
                </Typography>
                <Typography className={classes.vestingInfoText}>
                  {userData.claimable} ZAYA
                </Typography>
              </Box>
              {isSeedSale ? (
                <Button
                  className={classes.buyButton}
                  onClick={handleClaim}
                  disabled={!userData.claimable || isMaintenanceActive}
                >
                  {userVestingInfo &&
                  BigNumber(userVestingInfo.user_bought_amount).gt(BigZero) &&
                  saleCliffTimestamp > Date.now() / 1000
                    ? `Claim (in ${getDeltaTime(saleCliffTimestamp)})`
                    : 'Claim'}
                </Button>
              ) : (
                <Button
                  className={classes.buyButton}
                  onClick={handleClaim}
                  disabled={!userData.claimable || isMaintenanceActive}
                >
                  {userPrivateVestingInfo &&
                  BigNumber(userPrivateVestingInfo.user_bought_amount).gt(
                    BigZero
                  ) &&
                  privateSaleCliffTimestamp > Date.now() / 1000
                    ? `Claim (in ${getDeltaTime(privateSaleCliffTimestamp)})`
                    : 'Claim'}
                </Button>
              )}
              <Typography className={classes.stakeContent}>
                ZayaAI is a ground-breaking ecosystem that aims to combat
                cancers by leveraging the latest AI technologies and medical
                screening procedures to provide early detection, prevention, and
                treatment.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { Vesting };
