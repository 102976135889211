import { Box, Typography, Container } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';

import Wave from '../../../assets/img/telepathology_dx/wave.png';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingTop: '125px',
      minHeight: '100vh',
      height: 'auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        paddingTop: '75px'
      },
      position: 'relative',
      overflowX: 'hidden'
    },
    wave: {
      position: 'absolute',
      top: '10rem',
      width: '100%'
    },
    container: {
      marginTop: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '26px'
      }
    },
    title: {
      fontSize: '71px !important',
      fontWeight: '600 !important',
      lineHeight: '129% !important',
      color: '#20C2E5 !important',
      textAlign: 'center',
      [theme.breakpoints.down('lg')]: {
        fontSize: '60px !important'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '51px !important'
      }
    },
    content: {
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px'
      }
    },
    image: {
      width: '100%',
      maxWidth: '600px',
      [theme.breakpoints.down('lg')]: {
        maxWidth: '400px'
      }
    }
  })
);

const Enjinstarter = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={Wave} className={classes.wave} />
      <Container maxWidth='xl' className={classes.container}>
        <Box
          sx={{
            mt: { xs: '0', lg: '175px' },
            mb: '50px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            gap: '60px'
          }}
        >
          <Typography className={classes.title}>
            ZayaAI Partners with Enjinstarter to Transform Healthcare with AI
            and Blockchain Integration
          </Typography>
          <img src='/news/enjinstarter.jpg' className={classes.image} />
        </Box>
        <div>
          <p className={classes.content}>
            <b>[Bucharest, July 10th, 2024]</b> - ZayaAI, a pioneering
            AI-focused technology company, proudly announces its strategic
            partnership with Enjinstarter, a leading Web3 launchpad and
            advisory. The collaboration aims to harness the power of artificial
            intelligence and blockchain technology to revolutionize healthcare
            diagnostics and outcomes.
          </p>{' '}
          <br />
          <p className={classes.content}>
            ZayaAI’s innovative AI software empowers pathologists and
            researchers in clinical labs to reduce diagnosis times, decrease
            costs, minimize human error, and ultimately enhance patient care. By
            integrating blockchain technology, ZayaAI ensures data security,
            transparency, and improved collaboration across the healthcare
            ecosystem.
          </p>{' '}
          <br />
          <p>
            Through the synergy of ZayaAI’s state-of-the-art technology and
            Enjinstarter's expertise in Web3 solutions, both companies aspire to
            enhance the precision and efficiency of pathology diagnostics,
            setting new standards in healthcare innovation.
          </p>
          <br />
          <p>
            The partnership includes a strategic Initial DEX Offering (IDO)
            hosted by Enjinstarter, aimed at accelerating the development and
            deployment of ZayaAI’s cutting-edge diagnostic solutions. This IDO,
            set to launch on September , 2024, will provide crucial funding to
            expand ZayaAI’s reach and impact in the healthcare industry. Early
            investors will have the opportunity to support ZayaAI’s mission and
            be part of a transformative journey in medical diagnostics.
          </p>
          <br />
          <p className={classes.content}>
            <b>Cristian Mogodici, CEO of ZayaAI</b>, expressed his enthusiasm
            for the partnership: “We are thrilled to join forces with
            Enjinstarter. Their expertise in Web3 crowdfunding and blockchain
            technology perfectly complements our vision of driving innovation in
            healthcare through advanced digital solutions.”
          </p>{' '}
          <br />
          <p className={classes.content}>
            This partnership will leverage Enjinstarter’s robust blockchain
            platform to enhance ZayaAI’s diagnostic solutions, ensuring data
            integrity and fostering innovation in pathology and beyond. The
            collaboration will drive the development and deployment of advanced
            AI tools, bringing transformative changes to the healthcare
            industry.
          </p>{' '}
          <br />
          <a href='https://www.zaya.ai/' style={{ fontWeight: 500 }}>
            About ZayaAI
          </a>
          <p className={classes.content}>
            ZayaAI equips pathologists and researchers in clinical labs with
            powerful deep-learning artificial intelligence software for
            translating images into discoveries, decisions, and diagnoses. The
            cloud-based ZayaAI products and services aim to improve the
            efficiency and precision of pathology diagnosis and pathology image
            analysis beyond current capabilities across a variety of fields,
            from oncology to dermatopathology and more.
          </p>
        </div>
      </Container>
    </Box>
  );
};

export { Enjinstarter };
